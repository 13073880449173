<div class="toastr-wrapper" #toastrWrapperElement>
  <div
    class="toastr-status"
    [ngClass]="[statusClassName,borderShape]"
    (click)="clickEvent($event)"
  >
    <em class="di icon-status" [ngClass]="iconClassName"></em>
    <span class="message-status" [ngClass]="mensagemClassName">{{ toastrInfo?.mensagem || "" }}</span>
  </div>
</div>
