<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar"
    (window:scroll)="windowScroll()">
    <div class="container">
        <div class="navbar-container">
            <div class="logo-navbar">
                <img src="./assets/images/logo-portal.png" alt="logo portal de compras publicas" class="logo-portal">
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
                <span class="ti-menu"></span>
            </button>
            <div class="collapse navbar-collapse navbar-menu" id="navbarCollapse">
                <ul class="navbar-nav mx-auto" id="navbar-navlist">
                    <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
                        <a [ngxScrollTo]="'#pricing'" class="nav-link" href="#pricing">FACILIDADES</a>
                    </li>
                    <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
                        <a [ngxScrollTo]="'#contact'" class="nav-link" href="#contact">COMO FUNCIONA</a>
                    </li>
                    <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
                        <a [ngxScrollTo]="'#features'" class="nav-link" href="#features">O PORTAL</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- END NAVBAR -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
<!-- START HOME -->
<div class="background-color-box">
    <section class="section bg-home" id="home">
        <div class="container">
            <div class="container-content-index">
                <div class="text-container">
                    <div class="title-wrapper">
                        <h1 class="title-header">Receba avisos de licitação gratuitos com a ajuda de nossa Inteligência Artificial
                        </h1>
                    </div>
                    <div class="index-text-wrapper">
                        <p class="text-muted home-subtitle mt-4 mb-0 text-header"><br>Encontre a oportunidade perfeita para o seu negócio com a ajuda de nossa
                            <span>IA treinada e focada em licitações</span>.
                            <br>
                            <br>
                            São mais de
                            <span>200 novas e exclusivas</span>
                            oportunidades de vender para o governo todos os dias. Cadastre-se agora para aumentar a sua chance de vencer licitações e alavancar o seu faturamento.
                        </p>
                    </div>
                    <div class="mt-4 btn-wrapper">
                        <a (click)="handleClick()" class="btn btn-index" href="#navbar">QUERO RECEBER OPORTUNIDADES</a>
                    </div>
                </div>
                <div class="form-container" id="form">
                    <p class="form-title">Aviso de Licitações Gratuito</p>

                    <div class="toastr-container">
                      <div *ngFor="let toastr of toastrs">
                        <app-toastr
                          [toastrInfo]="toastr"
                          [autoDestroy]="false"
                          (clearToastr)="clearToastr($event)"
                        ></app-toastr>
                      </div>
                    </div>

                    <!-- STEP 1 -->
                    <form method="post" [formGroup]="form1" class="label-container" *ngIf="stepForm == 1" (ngSubmit)="onNext()">
                        <label for="nome" class="text-label">Seu nome<span class="field-required">obrigatório</span></label>
                        <input type="text" id="nome" name="nome" class="text-input form-control mb-2 registration-input-box"
                        formControlName="nome" aria-label="nome" (change)="onGenericFieldChange()">
                        <div *ngIf="form1.get('nome').invalid && (form1.get('nome').dirty || form1.get('nome').touched)" class="form-alert-error-wrapper">
                          <small class="form-alert-error" *ngIf="form1.get('nome').hasError('required')">Nome obrigatório.<br></small>
                          <small class="form-alert-error" *ngIf="form1.get('nome').hasError('minlength')">Nome inválido.<br></small>
                        </div>
                        <label for="whatsapp" class="text-label">Whatsapp<span class="field-required">obrigatório</span></label>
                        <input type="text" id="whatsapp" aria-label="whatsapp" name="whatsapp" class="text-input form-control mb-2 registration-input-box"
                        (change)="onGenericFieldChange()"
                        mask="(00) 0 0000-0000"
                        placeholder="(00) 00000-0000"
                        formControlName="whatsapp">
                        <div *ngIf="form1.get('whatsapp').invalid && (form1.get('whatsapp').dirty || form1.get('whatsapp').touched)" class="form-alert-error-wrapper">
                          <small class="form-alert-error" *ngIf="form1.get('whatsapp').hasError('required')">Whatsapp obrigatório.<br></small>
                          <small class="form-alert-error" *ngIf="form1.get('whatsapp').hasError('pattern')">Whatsapp inválido.<br></small>
                        </div>
                        <label for="email" class="text-label">E-mail<span class="field-required">obrigatório</span></label>
                        <input type="text" id="email" name="email" aria-label="email" class="text-input form-control mb-2 registration-input-box"
                        formControlName="email">
                        <div *ngIf="form1.get('email').invalid && (form1.get('email').dirty || form1.get('email').touched)" class="form-alert-error-wrapper">
                          <small class="form-alert-error" *ngIf="form1.get('email').hasError('required')">E-mail obrigatório.<br></small>
                          <small class="form-alert-error" *ngIf="form1.get('email').hasError('pattern')">E-mail inválido.<br></small>
                        </div>
                        <label for="cnpj" class="text-label">CNPJ<span class="field-required">obrigatório</span></label>
                        <input type="text" id="cnpj" aria-label="cnpj" name="cnpj" class="text-input form-control mb-2 registration-input-box"
                        (change)="onGenericFieldChange()"
                        placeholder="00.000.000/0000-00"
                        [mask]="'00.000.000/0000-00'"
                        formControlName="cnpj">
                        <div *ngIf="form1.get('cnpj').invalid && (form1.get('cnpj').dirty || form1.get('cnpj').touched)" class="form-alert-error-wrapper">
                            <small class="form-alert-error" *ngIf="form1.get('cnpj').hasError('required')">CNPJ obrigatório.<br></small>
                            <small class="form-alert-error" *ngIf="form1.get('cnpj').hasError('pattern')">CNPJ inválido.<br></small>
                        </div>
                        <label for="razaoSocial" id="razaoSocial" class="text-label">Razão social<span class="field-required">obrigatório</span></label>
                        <input type="text"
                        id="razaoSocial"
                        aria-label="razaoSocial"
                        (change)="onGenericFieldChange()"
                        name="razaoSocial"
                        class="text-input form-control mb-2 registration-input-box"
                        required
                        formControlName="razaoSocial">
                        <div *ngIf="form1.get('razaoSocial').invalid && (form1.get('razaoSocial').dirty || form1.get('razaoSocial').touched)" class="form-alert-error-wrapper">
                            <small class="form-alert-error" *ngIf="form1.get('razaoSocial').hasError('required')">Razão Social obrigatória.<br></small>
                            <small class="form-alert-error" *ngIf="form1.get('razaoSocial').hasError('minlength')">Razão Social inválida.<br></small>
                        </div>
                        <button type="submit" class="button-form-page" id="button-submit" [disabled]="form1.invalid">
                            Próximo</button>
                        <div class="mb-2 progressbar-container">
                          <progressbar class="progress-striped active" aria-label="progressbar" id="progress-bar" aria-valuetext="0" [value]="progressbarValue" [max]="100" type="success">{{progressbarValue}}%</progressbar>
                        </div>
                    </form>

                    <!-- STEP 2 -->
                    <form method="post" [formGroup]="form2" class="label-container" *ngIf="stepForm == 2" (ngSubmit)="onNext()">
                        <label for="" class="text-label">Estado<span class="field-required">obrigatório</span></label>
                        <ng-select
                        required
                        placeholder="Selecione um Estado"
                        name="estado"
                        [items]="estados"
                        bindLabel="sigla"
                        (change)="onEstadoChange(form2.get('estado').value?.sigla)"
                        formControlName="estado"
                        >
                        </ng-select>
                        <div *ngIf="form2.get('estado').invalid && (form2.get('estado').dirty || form2.get('estado').touched)" class="form-alert-error-wrapper">
                            <small class="form-alert-error" *ngIf="form2.get('estado').hasError('required')">Estado obrigatório.<br></small>
                        </div>
                        <label for="" class="text-label">Município<span class="field-required">obrigatório</span></label>
                        <ng-select
                        (change)="onGenericFieldChange()"
                        required
                        placeholder="Selecione um Município"
                        name="municipio"
                        [items]="municipios"
                        formControlName="municipio"
                        >
                        </ng-select>
                        <div *ngIf="form2.get('municipio').invalid && (form2.get('municipio').dirty || form2.get('municipio').touched)" class="form-alert-error-wrapper">
                            <small class="form-alert-error" *ngIf="form2.get('municipio').hasError('required')">Município obrigatório.<br></small>
                        </div>
                        <label for="" class="text-label">Deseja receber oportunidades para todo o Brasil, ou só para o seu Estado?<span class="field-required">obrigatório</span></label>
                        <div class="form-options-container">
                          <div class="form-options-wrapper" btnRadioGroup formControlName="oportunidadesBrasil">
                            <label btnRadio="Apenas meu Estado" class="btn form-options-button">Apenas meu Estado</label>
                            <label btnRadio="Todo o Brasil" class="btn form-options-button">Todo o Brasil</label>
                          </div>
                        </div>
                        <div *ngIf="form2.get('oportunidadesBrasil').invalid && (form2.get('oportunidadesBrasil').dirty || form2.get('oportunidadesBrasil').touched)" class="form-alert-error-wrapper">
                            <small class="form-alert-error" *ngIf="form2.get('oportunidadesBrasil').hasError('required')">Campo obrigatório.<br></small>
                        </div>
                        <label for="" class="text-label">Escolha a linha de fornecimento do seu negócio:<span class="field-required">obrigatório</span></label>
                        <ng-select
                        (change)="onGenericFieldChange()"
                        required
                        placeholder="Selecione uma categoria"
                        name="categoriaFornecimento"
                        [items]="categorias"
                        bindLabel="nomeGrupo"
                        (change)="onCategoriaChange(form2.get('categoriaFornecimento').value?.CodigoCategoria)"
                        formControlName="categoriaFornecimento">
                        </ng-select>
                        <div *ngIf="form2.get('categoriaFornecimento').invalid && (form2.get('categoriaFornecimento').dirty || form2.get('categoriaFornecimento').touched)" class="form-alert-error-wrapper">
                            <small class="form-alert-error" *ngIf="form2.get('categoriaFornecimento').hasError('required')">Campo obrigatório.</small>
                        </div>

                        <label for="" class="text-label-ia">Digite <span class="text-label-bold">apenas a palavra-chave</span> do produto/serviço que você fornece e nossa IA te ajudará a configurar o seu aviso de licitações.
                          <span class="text-label-bold">Faça uma busca por vez, pesquise quantas vezes quiser.</span></label>
                        <div class="search-field-container">
                          <input type="text"
                            (change)="onGenericFieldChange()"
                            (keypress)="onKeyPress($event)"
                            name="palavraPesquisaGpt"
                            class="text-input form-control mb-2 registration-input-box search-field"
                            formControlName="palavraPesquisaGpt">
                          <button type="button" class="btn button-search-form" (click)="handlePesquisarGptClick()" [disabled]="form2.get('palavraPesquisaGpt').value?.length < 4">
                            <img src="./assets/images/ico-lupa-branco.png" class="searching-img" alt="lupa" *ngIf="!gptIsLoading">
                            <img src="./assets/images/seta-redonda2.svg" class="searching-img-animated" alt="gif pesquisando" *ngIf="gptIsLoading">
                          </button>
                        </div>

                        <div class="basic-search-wrapper">
                          <label for="" class="basic-search" (click)="handleGenericSearchClick()">Ver todas as opções sem filtro da IA</label>
                        </div>

                        <label for="" class="text-label" *ngIf="subCategorias?.length != 0 || form2.get('subCategoriaFornecimento').value?.length != 0">Escolha a(s) subcategoria(s) que mais encaixam com o seu negócio: - <span class="field-required">obrigatório</span></label>
                        <ng-select
                        *ngIf="subCategorias?.length != 0 || form2.get('subCategoriaFornecimento').value?.length != 0"
                        #listSubCategoria
                        (change)="onGenericFieldChange()"
                        [dropdownPosition]="'bottom'"
                        required
                        placeholder="Selecione uma subcategoria"
                        name="subCategoriaFornecimento"
                        [items]="subCategorias"
                        [multiple]="true"
                        [closeOnSelect]="false"
                        bindLabel="codigoMaterial"
                        formControlName="subCategoriaFornecimento"
                        >
                        </ng-select>
                        <div *ngIf="(subCategorias?.length != 0 || form2.get('subCategoriaFornecimento').value?.length != 0) && form2.get('subCategoriaFornecimento').invalid && (form2.get('subCategoriaFornecimento').dirty || form2.get('subCategoriaFornecimento').touched)" class="form-alert-error-wrapper">
                            <small class="form-alert-error" *ngIf="form2.get('subCategoriaFornecimento').hasError('required')">Campo obrigatório.</small>
                        </div>
                        <button type="submit" class="button-form-page" id="button-submit" [disabled]="form2.invalid || form2.get('subCategoriaFornecimento').value?.length == 0">
                            Próximo</button>
                        <div class="mb-2 progressbar-container">
                          <progressbar class="progress-striped active" aria-label="progressbar" id="progress-bar" aria-valuetext="0" [value]="progressbarValue" [max]="100" type="success">{{progressbarValue}}%</progressbar>
                        </div>
                    </form>

                    <!-- STEP 3 -->
                    <form method="post" [formGroup]="form3" class="label-container" *ngIf="stepForm == 3" (ngSubmit)="onSubmit()">
                        <label for="" class="text-label">Eu sou:<span class="field-required">obrigatório</span></label>
                        <div class="form-options-container">
                          <div class="form-options-wrapper form-options-wrapper-eusou" btnRadioGroup formControlName="euSou">
                            <label btnRadio="Empresario" (change)="onGenericFieldChange()" class="btn form-options-button form-options-button-eusou">Empresário</label>
                            <label btnRadio="Consultor" (change)="onGenericFieldChange()" class="btn form-options-button form-options-button-eusou">Consultor</label>
                            <label btnRadio="Analista" (change)="onGenericFieldChange()" class="btn form-options-button form-options-button-eusou">Analista</label>
                            <label btnRadio="Outro" (change)="onGenericFieldChange()" class="btn form-options-button form-options-button-eusou">Outro</label>
                          </div>
                        </div>
                        <div *ngIf="form3.get('euSou').invalid && (form3.get('euSou').dirty || form3.get('euSou').touched)" class="form-alert-error-wrapper">
                            <small class="form-alert-error" *ngIf="form3.get('euSou').hasError('required')">Campo obrigatório.</small>
                        </div>
                        <label for="" class="text-label">Você ou sua empresa já participou de licitações?<span class="field-required">obrigatório</span></label>
                        <div class="form-options-container">
                          <div class="form-options-wrapper" btnRadioGroup formControlName="licitante">
                            <label btnRadio="true" (change)="onGenericFieldChange()" class="btn form-options-button button-sim-nao">Sim</label>
                            <label btnRadio="false" (change)="onGenericFieldChange()" class="btn form-options-button button-sim-nao">Não</label>
                          </div>
                        </div>
                        <div *ngIf="form3.get('licitante').invalid && (form3.get('licitante').dirty || form3.get('licitante').touched)" class="form-alert-error-wrapper">
                            <small class="form-alert-error" *ngIf="form3.get('licitante').hasError('required')">Campo obrigatório.<br></small>
                        </div>
                        <label for="" class="text-label">Tem interesse em cursos gratuitos de licitação?<span class="field-required">obrigatório</span></label>
                        <div class="form-options-container">
                          <div class="form-options-wrapper" btnRadioGroup formControlName="interesseCursos">
                            <label btnRadio="true" (change)="onGenericFieldChange()" class="btn form-options-button button-sim-nao">Sim</label>
                            <label btnRadio="false" (change)="onGenericFieldChange()" class="btn form-options-button button-sim-nao">Não</label>
                          </div>
                        </div>
                        <div *ngIf="form3.get('interesseCursos').invalid && (form3.get('interesseCursos').dirty || form3.get('interesseCursos').touched)" class="form-alert-error-wrapper">
                            <small class="form-alert-error" *ngIf="form3.get('interesseCursos').hasError('required')">Campo obrigatório.<br></small>
                        </div>
                        <label  class="text-label-termos">
                          <input type="checkbox" formControlName="termos">
                          Aceitar
                          <a href="https://portaldecompraspublicas.com.br/sobre/politica-privacidade" class="text-label-termos-link" target="_blank"> política de privacidade</a>
                        </label>
                        <button type="submit" class="button-form-page" id="button-submit" [disabled]="form3.invalid" *ngIf="!submitIsLoading">
                            Enviar</button>
                        <button type="submit" class="button-form-page" id="button-submit" *ngIf="submitIsLoading">
                          <img src="./assets/images/seta-redonda2.svg" class="searching-img-animated" alt="gif pesquisando">
                        </button>
                        <div class="mb-2 progressbar-container">
                          <progressbar class="progress-striped active" aria-label="progressbar" id="progress-bar" aria-valuetext="0" [value]="100" [max]="100" type="success">{{progressbarValue}}%</progressbar>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <div class="container">
        <div class="numbers-container">
            <div class="first-block">
                <p class="first-lane">+200</p>
                <p class="second-lane">Vencedores por dia</p>
            </div>
            <div class="second-block">
                <p class="first-lane">+250</p>
                <p class="second-lane">Novos processos</p>
            </div>
            <div class="third-block">
                <p class="first-lane">+<span>R$</span>5BI</p>
                <p class="second-lane">Transacionados por mês</p>
            </div>
            <div class="forty-block">
                <p class="first-lane">+<span>R$</span>100BI</p>
                <p class="second-lane">Transacionados em 2022</p>
            </div>
        </div>
    </div>
</div>
<!-- END HOME -->
<div>
    <app-facilidades></app-facilidades>
    <app-comofunciona></app-comofunciona>
    <app-opcp></app-opcp>
    <app-footer></app-footer>
    <app-switcher></app-switcher>
</div>
