import { Categorias } from './categorias';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoriasGptRequest } from './categoriasGptRequest';
import { FornecedorDto } from './fornecedordto';
import { CategoriasOut } from './categoriasout';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  constructor(private http: HttpClient) { }

  getEstados(): Observable<any> {
    return this.http.get<any>('assets/estados-cidades.json');
  }

  getMunicipios(): Observable<any> {
    return this.http.get<any>('assets/municipios.json')
  }

  getCategorias(): Observable<Categorias[]> {
    return this.http.get<Categorias[]>('assets/categorias-materiais-in.json');
  }

  getCategoriasOut(): Observable<CategoriasOut[]> {
    return this.http.get<CategoriasOut[]>('assets/categorias-materiais-out.json');
  }

  getCategoriasGpt(categoriasGptRequest: CategoriasGptRequest): Observable<any> {
    return this.http.post<any>(environment.URLGetCompletion, categoriasGptRequest);
  }

  postCadastro(fornecedorDto : FornecedorDto) {
    return this.http.post<FornecedorDto>(environment.URLFornecedorRegister, fornecedorDto);
  }

  postDiscord(message: any) {
    const payload = {
      content: message,
      username: environment.UserNameWebhookDiscord
    };

    return this.http.post<string>(environment.URLWebhookDiscord, payload);
  }

}
