import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgSelectComponent, NgSelectConfig } from '@ng-select/ng-select';
import { Categorias } from 'src/app/core/categorias';
import { Estados } from 'src/app/core/estados';
import { RequestsService } from 'src/app/core/requests.service';
import { CategoriasGptRequest } from 'src/app/core/categoriasGptRequest';
import { FornecedorDto } from 'src/app/core/fornecedordto';
import { SubCategoriasSend } from 'src/app/core/subcategoriassend';
import { CategoriasOut } from 'src/app/core/categoriasout';
import { SubCategorias } from 'src/app/core/subcategorias';
import { Toastr } from 'src/app/core/toastr';
import { TypeMessageEnum } from 'src/app/shared/type-message.enum';
import { Municipios } from 'src/app/core/municipios';


@Component({
  selector: 'app-index1',
  templateUrl: './index1.component.html',
  styleUrls: ['./index1.component.scss']
})


export class Index1Component implements OnInit {

  @ViewChild('listSubCategoria') listSubCategoria: NgSelectComponent;

  form1: FormGroup;
  form2: FormGroup;
  form3: FormGroup;

  constructor(
    private ngSelectConfig: NgSelectConfig,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private requestService: RequestsService,
    private route: ActivatedRoute
  ) {
    this.ngSelectConfig.notFoundText = 'Nenhuma informação encontrada.';
    this.form1 = new FormGroup({
      nome: new FormControl(null, [Validators.required, Validators.minLength(4)]),
      whatsapp: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]{11}$")]),
      email: new FormControl(null, [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$")]),
      cnpj: new FormControl(null, [Validators.required, Validators.pattern(/^\d{14}$/)]),
      razaoSocial: new FormControl(null, [Validators.required, Validators.minLength(4)])
    });
    this.form2 = new FormGroup({
      estado: new FormControl(null, Validators.required),
      municipio: new FormControl({value: null, disabled: true}, Validators.required),
      oportunidadesBrasil: new FormControl('Apenas meu Estado', Validators.required),
      categoriaFornecimento: new FormControl(null, Validators.required),
      palavraPesquisaGpt: new FormControl({value: null, disabled: true}),
      subCategoriaFornecimento: new FormControl({value: [], disabled: true})
    });
    this.form3 = new FormGroup({
      euSou: new FormControl('Empresario', Validators.required),
      licitante: new FormControl('false', Validators.required),
      interesseCursos: new FormControl('true', Validators.required),
      termos: new FormControl (false, Validators.requiredTrue)
    });
  }

  currentSection = 'home';

  public stepForm = 1;
  estados: Estados[] = [];
  municipiosDTO: Municipios[] = [];
  municipios: string[] = [];
  categorias: Categorias[] = [];
  subCategorias: SubCategorias[] = [];
  categoriasOut : CategoriasOut[] = [];
  gptIsLoading: boolean = false;
  submitIsLoading: boolean = false;
  progressbarValue: number = 0;
  toastrs: Toastr[] = [];

  private fornecedorDto : FornecedorDto = {
    razaoSocial : "",
    cnpj : "",
    cpf : "",
    cpfRepresentante : "",
    email : "",
    nome: "",
    telefone : "",
    tipoUsuario : "",
    planoAdesao : null,
    formaPagamento : null,
    termos : null,
    codigoOrigemCadastro : null,
    endereco : {
      cep : "",
      uf : {
        codigoUf : null,
        siglaUf : ""
      },
      municipio : {
        codigo : null,
        nome : "",
      },
      bairro : "",
      endereco : "",
      complemento : "",
      numero : ""
    },
    dadosCampanha : {
      licitante : null,
      receberConteudos : null,
      receberCursos : null,
      receberDicas : null,
      receberJornal : null
    },
    cookieCampanha : {
      source : "",
      campanha : ""
    },
    ufs : [],
    classesMateriais : []
  };
  private utmSource = null;
  private utmMedium = null;
  private utmCampaign = null;
  private utmTerm = null;

  ngOnInit(): void {
    this.requestEstados();
    this.requestMunicipios();
    this.requestCategorias();
    this.requestCategoriasOut();
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utm_source'];
      this.utmMedium = params['utm_medium'];
      this.utmCampaign = params['utm_campaign'];
      this.utmTerm = params['utm_term'];
    });
  }



  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
      navbar.style.backgroundColor = '#1a1a1a';
      navbar.style.padding = '15px 0px';
    }
    else {
      navbar.style.backgroundColor = '';
      navbar.style.padding = '20px';
    }
  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  async onSubmit() : Promise<void> {
    this.dtoCreate();
    await this.requestFornecedorCreate();
  }

  onNext(form?: NgForm){
    this.stepForm++;
    const formHtml = document.getElementById('form');
    formHtml.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  onNextFormGroup(form?: FormGroup) {
    this.stepForm++;
    window.scroll(0, 0);
  }

  async handlePesquisarGptClick(): Promise<void> {
    await this.requestGpt();
    this.cdr.detectChanges();
    this.listSubCategoria.open();
  }

  async handleGenericSearchClick(): Promise<void> {
    const categoriaSelecionada: Categorias = this.categorias.find(categoria => categoria.CodigoCategoria == this.form2.get('categoriaFornecimento').value?.CodigoCategoria);
    this.subCategorias = categoriaSelecionada.topicos;
    this.form2.get('subCategoriaFornecimento').enable();
    this.cdr.detectChanges();
    this.listSubCategoria.open();
  }

  handleClick() {
    window.scroll(0,0);
  }

  requestEstados() {
    this.requestService
    .getEstados()
    .subscribe((data: any) => {
      this.estados = data.estados;
    });
  }

  requestMunicipios() {
    this.requestService
    .getMunicipios()
    .subscribe((data: any) => {
      this.municipiosDTO = data.municipios;
    });
  }

  requestCategorias() {
    this.requestService
    .getCategorias()
    .subscribe(
      (data: Categorias[]) => {
        this.categorias = data;
      }
    );
  }

  requestCategoriasOut() {
    this.requestService
    .getCategoriasOut()
    .subscribe(
      (data: CategoriasOut[]) => {
        this.categoriasOut = data;
      }
    );
  }

  public clearToastr(...toastrToClear: any[]): void {
    this.toastrs = this.toastrs.filter((toastr: any) => {
      return !toastrToClear.includes(toastr);
    });
  }

  private exibeToastr(mensagem: string, status: TypeMessageEnum): void {
    this.clearToastr(...this.toastrs);
    if (this.toastrs.find((toastr) => toastr.mensagem === mensagem)) {
      return;
    }
    this.toastrs.push({
      mensagem,
      status
    });

    window.scroll(0, 0);
    return;
  }

  onCategoriaChange(selectedCategoria: any) {
    if(selectedCategoria){
      this.subCategorias = [];
      this.form2.get('palavraPesquisaGpt').enable();
      return;
    }
    this.subCategorias = [];
    this.form2.get('subCategoriaFornecimento').setValue(null);
    this.form2.get('subCategoriaFornecimento').disable();
    this.form2.get('palavraPesquisaGpt').disable();
  }


  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  onGenericFieldChange(): void {
    this.calculateProgressbarValue();
  }

  onEstadoChange(selectedEstado: string) {
    this.calculateProgressbarValue();
    if(selectedEstado != this.form2.get('estado').value){
      this.form2.get('municipio').setValue(null);
    }
    if(selectedEstado){
      const estadoSelecionado = this.estados.find(estado => estado.sigla === selectedEstado);
      this.municipios = estadoSelecionado ? estadoSelecionado.cidades : [];
      this.form2.get('municipio').enable();
      return;
    }
    this.municipios = [];
    this.form2.get('municipio').setValue(null);
    this.form2.get('municipio').disable();
  }

  onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (this.form2.get('palavraPesquisaGpt').value?.length >= 4) {
        this.handlePesquisarGptClick();
      }
      event.preventDefault();
    }
  }

  private calculateProgressbarValue(): void {
    const allForms = [this.form1, this.form2, this.form3];
    this.progressbarValue = 0;
    allForms.forEach(form => {
      for(let controlName in form.controls) {
        const control = form.controls[controlName];
        if(control.valid && (control.value != null && control.value != undefined) && !control.disabled) {
          this.progressbarValue += 7.14;
          this.progressbarValue = Math.round(this.progressbarValue);
        }
      }
    });
  }

  private categoriasOutCreate(): SubCategoriasSend[] {
    const categoriasSelected : SubCategorias[] = this.form2.get('subCategoriaFornecimento').value;
    const subCategoriasSelectedCodes = categoriasSelected.map(subCategoria => subCategoria.codigoCategoria);
    const subCategoriasSend : SubCategoriasSend[] = this.categoriasOut
      .reduce((acc: SubCategoriasSend[], categoria: CategoriasOut) => {
      const topicosToSend = categoria.topicos
        .filter(topico => subCategoriasSelectedCodes.includes(String(topico.codigo)))
        .map(topico => {
          return {
            codigo: topico.codigo,
            grupoMaterial: {
              codigo: categoria.codigo
            }
          };
        });

      return [...acc, ...topicosToSend];
    }, []);

    return subCategoriasSend;
  }

  private dtoCreate() {
    this.fornecedorDto.razaoSocial = this.form1.get('razaoSocial').value;
    this.fornecedorDto.cnpj = this.form1.get('cnpj').value;
    this.fornecedorDto.email = this.form1.get('email').value;
    this.fornecedorDto.nome = this.form1.get('nome').value;
    this.fornecedorDto.telefone = this.form1.get('whatsapp').value;
    this.fornecedorDto.tipoUsuario = 'Fornecedor';
    this.fornecedorDto.planoAdesao = 0;
    this.fornecedorDto.formaPagamento = 0;
    this.fornecedorDto.termos = true;
    this.fornecedorDto.codigoOrigemCadastro = 13;
    this.fornecedorDto.endereco.uf.codigoUf = this.form2.get('estado').value.codigo;
    this.fornecedorDto.endereco.uf.siglaUf = this.form2.get('estado').value.sigla;
    this.fornecedorDto.endereco.municipio.nome = this.form2.get('municipio').value;
    const nomeMunicipio = this.fornecedorDto.endereco.municipio.nome;
    this.fornecedorDto.endereco.municipio.codigo = this.municipiosDTO.find(municipio => municipio.NOME_MUNICIPIO === nomeMunicipio).CD_MUNICIPIO;
    this.fornecedorDto.dadosCampanha.licitante = Boolean(this.form3.get('licitante').value);
    this.fornecedorDto.dadosCampanha.receberConteudos = Boolean(this.form3.get('interesseCursos').value);
    this.fornecedorDto.dadosCampanha.receberCursos = Boolean(this.form3.get('interesseCursos').value);
    this.fornecedorDto.dadosCampanha.receberDicas = Boolean(this.form3.get('interesseCursos').value);
    this.fornecedorDto.dadosCampanha.receberJornal = true;
    this.fornecedorDto.cookieCampanha.source = this.utmSource ? this.utmSource : "";
    this.fornecedorDto.cookieCampanha.campanha = this.utmCampaign ? this.utmCampaign : "";
    if(this.form2.get('oportunidadesBrasil').value == 'Apenas meu Estado') {
      this.fornecedorDto.ufs = [];
      this.fornecedorDto.ufs.push(this.fornecedorDto.endereco.uf.codigoUf);
    } else {
      this.fornecedorDto.ufs = [];
      this.estados.forEach(estado => this.fornecedorDto.ufs.push(estado.codigo));
    }
    this.fornecedorDto.ufs.push(this.form2.get('oportunidadesBrasil').value == 'Apenas meu Estado' ? this.fornecedorDto.endereco.uf.codigoUf : 0);
    this.fornecedorDto.classesMateriais = this.categoriasOutCreate();
  }

  private requestFornecedorCreate(): Promise<void> {
    this.submitIsLoading = true;
    return new Promise((resolve, reject) => {
      this.requestService.postCadastro(this.fornecedorDto)
        .subscribe((data: any) => {
          this.submitIsLoading = false;
          this.router.navigate(['/obrigado-1-aviso-de-licitacoes']);
          window.scroll(0,0);
          resolve();
        },
        async (error) => {
          this.stepForm = 1;
          const messageError = this.messageErrorTranslate(error.error.message);
          this.exibeToastr(messageError, TypeMessageEnum.IMPEDITIVO);
          const messageErrorDiscord = this.generateMessageErrorDiscord(JSON.stringify(error.error), JSON.stringify(this.fornecedorDto));
          await this.requestMessageDiscord(messageErrorDiscord);
          this.submitIsLoading = false;
          reject();
        })
    });
  }

  private requestMessageDiscord(message: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postDiscord(message)
        .subscribe((data: any) => {
          resolve();
        },
        (error) => {
          reject();
        })
    });
  }

  private requestGpt(): Promise<void> {
    this.gptIsLoading = true;

    const categoriaSelecionada: Categorias = this.categorias.find(categoria => categoria.CodigoCategoria == this.form2.get('categoriaFornecimento').value?.CodigoCategoria);

    const categoriaGptRequest: CategoriasGptRequest = {
      codigo: categoriaSelecionada.CodigoCategoria,
      nome: categoriaSelecionada.nomeGrupo,
      palavra: this.form2.get('palavraPesquisaGpt').value,
      topicos: categoriaSelecionada.topicos.map(item => ({
        codigo: item.codigoCategoria,
        nome: item.codigoMaterial
      }))
    };

    return new Promise((resolve, reject) => {
      this.requestService.getCategoriasGpt(categoriaGptRequest)
        .subscribe((data: any) => {
          const codigosRetorno: number[] = data.data;
          const newSubCategorias = codigosRetorno.map(codigo => categoriaGptRequest.topicos.find(subCategoria => subCategoria.codigo == String(codigo)));
          this.subCategorias = newSubCategorias.map(item => ({
            codigoCategoria: item.codigo,
            codigoMaterial: item.nome
          }));
          this.form2.get('subCategoriaFornecimento').enable();
          this.gptIsLoading = false;
          resolve();
        },
        (error) => {
          this.subCategorias = categoriaSelecionada.topicos;
          this.form2.get('subCategoriaFornecimento').enable();
          this.gptIsLoading = false;
          reject();
        })
    });
  }

  private messageErrorTranslate(messageErrorReceived: string): string {
    switch(messageErrorReceived) {
      case 'Business Layer: Já existe cadastro para o documento informado, favor verificar. Clique aqui caso tenha esquecido sua senha.' :
        return 'Já existe cadastro vinculado a este CNPJ. Caso não se lembre, entre em contato com a nossa Central 3003-5455.';

      case 'Business Layer: O email escolhido por você já esta sendo utilizado no Portal, favor tentar outro login.':
        return 'O e-mail inserido já está cadastrado no Portal, por favor tente cadastrar outro e-mail.';

      default:
        return 'Erro ao cadastrar dados. Por favor, verifique os campos preenchidos e em caso de persistência, entre em contato com a nossa Central 3003-5455.';
    }
  }

  private generateMessageErrorDiscord(message: string, payload: string): string {
    const dataAtual: Date = new Date();

    return `Erro ao ser realizada uma conversão na Landing Page - ${dataAtual.toLocaleDateString()} ${dataAtual.toLocaleTimeString()}
    \nObjeto enviado na requisição:${payload}
    \nErro recebido do servidor:\n${message}`;
  }
}
