import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, HostListener, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Estados } from 'src/app/core/estados';
import { Processos } from 'src/app/core/processos';
import { environment } from 'src/environments/environment.prod'

@Component({
  selector: 'app-index2',
  templateUrl: './index2.component.html',
  styleUrls: ['./index2.component.scss']
})


export class Index2Component implements OnInit {

  form: FormGroup;
  utmSource: string;
  utmMedium: string;
  isMobileScreen: boolean;
  isLoading: boolean = false;
  isEmpty: boolean = false;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.titleService.setTitle('Sucesso - Aviso de Licitações - Portal de Compras Públicas');
    this.checkScreenWidth();
    this.form = new FormGroup({
      estado: new FormControl(null, Validators.required),
      palavra: new FormControl(null, [Validators.required, Validators.minLength(4)])
    });
  }

  currentSection = 'home';
  processos: Processos[] = [];
  estados: Estados[] = [];


  ngOnInit(): void {
    this.getEstados();
    this.activatedRoute.queryParams.subscribe(params => {
      this.utmSource = params['utm_source'];
      this.utmMedium = params['utm_medium'];
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkScreenWidth();
  }

  checkScreenWidth() {
    this.isMobileScreen = window.innerWidth < 1024;
  }

  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
      navbar.style.backgroundColor = '#1a1a1a';
      navbar.style.padding = '15px 0px';
    }
    else {
      navbar.style.backgroundColor = '';
      navbar.style.padding = '20px';
    }
  }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  handleLogoClick() {
    window.scroll(0,0);
  }

  handleSearchClick() {
    this.isLoading = true;
    this.getProcessos().subscribe(() => {
      this.isLoading = false;
      if (window.innerWidth > 768) {
        window.scroll(0, 500);
      }
      this.form.reset();
      this.form.get('estado').setValue(this.estados.find(estado => estado.codigo == 0));
    });
  }

  handleProcessClick(processo: Processos): void {
    const url = 'https://www.portaldecompraspublicas.com.br/processos' + processo.urlReferencia;
    window.open(url, '_blank');
  }

  getProcessos(): Observable<any[]> {

    const objeto = this.form.get('palavra').value;
    const codigoUf = this.form.get('estado').value.codigo;
    this.form.reset();
    this.processos = [];
    this.isEmpty = false;

    const params = new HttpParams()
      .set('objeto', objeto)
      // CODIGO STATUS 1 = LICITACOES EM ABERTO
      .set('codigoStatus', 1)
      .set('codigoUf', String(codigoUf));

    return this.http.get<any[]>(environment.URLGetProcess, { params: params }).pipe(
      tap((data: any) => {
        this.isEmpty = !data.result.length;
        data.result.forEach((data: any) => {
          const processo: Processos = {
            identificacao: data.numero,
            resumo: data.resumo,
            statusDescricao: data.status.descricao,
            codModalidadeLicitacao: data.tipoLicitacao.codigoModalidadeLicitacao,
            tipoLicitacao: data.tipoLicitacao.tipoLicitacao,
            dataHoraFinalPropostas: this.formatDate(data.dataHoraFinalPropostas),
            nomeUnidadeCompradora: data.unidadeCompradora.nomeUnidadeCompradora,
            ufUnidadeCompradora: data.unidadeCompradora.uf,
            urlReferencia: data.urlReferencia,
            descricaoProcesso: data.statusProcessoPublico.descricao
          };
          this.processos.push(processo);
        });
      })
    );
  }

  getEstados() {
    this.http.get<Estados[]>('assets/estados-cidades.json').subscribe(
      (data: any) => {
        this.estados = data.estados;
        this.estados.unshift({
          codigo: 0,
          sigla: 'Todos',
          nome: 'Todos',
          cidades: []
        });
        const todos = this.estados.find(estado => estado.codigo == 0);
        this.form.get('estado').setValue(todos);
      }
    );
  }

  private formatDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} às ${hours}:${minutes}`;
  }

}
