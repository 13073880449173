import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { ServicesComponent } from './services/services.component';
import { OpcpComponent } from './opcp/opcp.component';
import { FacilidadesComponent } from './facilidades/facilidades.component';
import { ComofuncionaComponent } from './comofunciona/comofunciona.component';
import { FooterComponent } from './footer/footer.component';
import { SwitcherComponent } from './switcher/switcher.component';
import { ScrollspyDirective } from './scrollspy.directive';
import { QuaselaComponent } from './quasela/quasela.component';
import { CardsObrigadoComponent } from './cards-obrigado/cards-obrigado.component';
import { ToastrComponent } from './toastr/toastr.component';

@NgModule({
  declarations: [ServicesComponent, OpcpComponent, FacilidadesComponent, QuaselaComponent, CardsObrigadoComponent, ComofuncionaComponent, FooterComponent, SwitcherComponent, ScrollspyDirective, ToastrComponent],
  imports: [
    CommonModule,
    CarouselModule,
    ScrollToModule.forRoot()
  ],
  exports: [ServicesComponent, OpcpComponent, FacilidadesComponent, QuaselaComponent, CardsObrigadoComponent, ComofuncionaComponent, FooterComponent, SwitcherComponent, ScrollspyDirective, ToastrComponent]
})
export class SharedModule { }
