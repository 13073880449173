<!-- STRAT NAVBAR -->
<nav
  class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
  id="navbar"
  (window:scroll)="windowScroll()"
>
  <div class="container">
    <div class="navbar-container navbar-just-logo">
      <div class="logo-navbar logo-click">
        <img
          src="./assets/images/logo-portal.png"
          alt="logo portal de compras publicas"
          class="logo-portal"
          (click)="handleLogoClick()"
        />
      </div>
    </div>
  </div>
</nav>
<!-- END NAVBAR -->
<div
  appScrollspy
  [spiedTags]="['SECTION']"
  (sectionChange)="onSectionChange($event)"
>
  <!-- START HOME -->
  <div class="obrigado-background-color-box">
    <section class="section bg-obrigado" id="home">
      <div class="container">
        <div class="container-content-obrigado">
            <div class="obrigado1-text-container">
                <h1 class="obrigado1-title">
                    Não perca a chance, busque oportunidades agora.
                </h1>
                <p class="obrigado1-text">
                    A sua oportunidade de vencer uma licitação pode estar a um clique de você! Não deixe a chance passar, busque agora licitações para os produtos ou serviços que você vende!
                </p>
            </div>
            <form method="post" [formGroup]="form" class="obrigado1-search-container" (ngSubmit)="handleSearchClick()">
                <div class="obrigado1-first-div-input">
                    <p class="obrigado1-title-input padding-left">
                        UF
                    </p>
                    <ng-select
                    required
                    placeholder="Estado"
                    name="estado"
                    [items]="estados"
                    bindLabel="sigla"
                    class="obrigado-first-input"
                    formControlName="estado"
                    ></ng-select>
                </div>
                <div class="obrigado1-second-div-input">
                    <p class="obrigado1-title-input">
                        Produto ou serviço
                    </p>
                    <input
                    type="text"
                    name="palavra"
                    class="obrigado-second-input"
                    placeholder="Termo ou palavra-chave do que você quer vender para o governo"
                    required
                    formControlName="palavra">
                </div>
                <button type="submit" class="obrigado-search-button" id="button-submit" [disabled]="form.invalid" *ngIf="!isLoading">Buscar</button>
                <button type="submit" class="obrigado-search-button" id="button-submit" [disabled]="isLoading" *ngIf="isLoading">
                  <img src="./assets/images/seta-redonda2.svg" class="searching-img-animated" alt="gif pesquisando">Buscando</button>
            </form>
        </div>
      </div>
    </section>
  </div>
  <section class="bg-processos">
    <div class="container" *ngIf="isEmpty">
      <div class="obrigado1-process-container">
        <div class="text-noprocess-container">
          <h2 class="text-process-title">
            Não encontramos nenhum processo com os parâmetros informados.
          </h2>
          <p class="text-process-text">
            Tente ampliar os Estados ou utilizar termos diferentes.
          </p>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="processos.length != 0">
      <div class="obrigado1-process-container">
        <div class="text-process-container">
          <h2 class="text-process-title">
            Confira o que encontramos para você!
          </h2>
          <p class="text-process-text">
            Estas são as oportunidades exclusivas do Portal para a sua pesquisa.
          </p>
        </div>
        <div class="process-container">
          <div class="process-container-item" *ngFor="let processo of processos">
            <div class="process-item-first-row">
              <div class="process-item-first-row-column1">
                <h1 class="process-resumo">{{ processo.resumo.length > 300 ? (processo.resumo | slice:0:100) + '...' : processo.resumo }}</h1>
              </div>
              <div class="process-item-first-row-column2">
                <p class="process-identificacao">
                  {{ processo.identificacao }}
                </p>
                <div class="process-status-wrapper">
                  <img src="./assets/images/SVG.png" alt="ícone status" class="icon-process-status">
                  <p class="process-status"> {{ processo.statusDescricao }}</p>
                </div>
                <button class="acessar-processo btn" (click)="handleProcessClick(processo)"> ACESSAR PROCESSO </button>
              </div>
            </div>
            <div class="process-item-second-row">
              <div class="process-item-second-row-row1">
                <div class="process-info-wrapper">
                  <img src="./assets/images/icon-calendario.png" alt="ícone calendário" class="icon-process-info">
                  <p class="process-info"> {{ processo.dataHoraFinalPropostas }}</p>
                </div>
                <div class="process-info-wrapper">
                  <img src="./assets/images/martelo3.png" alt="ícone martelo" class="icon-process-info">
                  <p class="process-info"> {{ processo.tipoLicitacao }}</p>
                </div>
              </div>
              <div class="process-info-wrapper">
                <img src="./assets/images/locale.png" alt="ícone local" class="icon-process-info">
                <p class="process-info"> {{ processo.nomeUnidadeCompradora + " - " + processo.ufUnidadeCompradora }}</p>
              </div>
              <div class="process-info-wrapper"  *ngIf="processo.codModalidadeLicitacao != 3 && processo.codModalidadeLicitacao != 10">
                <img src="./assets/images/atencao.png" alt="ícone atenção" class="icon-process-info">
                <p class="process-info">
                  Para participar deste processo exclusivo, você precisa ter um plano pago no Portal de Compras Públicas.
                  <a class="text-hyperlink" href="https://portaldecompraspublicas.com.br/adesao/fornecedor?utm_source=redirect&utm_campaign=cadastro-aviso-de-licitacoes" target="_blank">Confira os planos</a>.
                </p>
              </div>
              <div class="process-info-wrapper"  *ngIf="processo.codModalidadeLicitacao == 3 || processo.codModalidadeLicitacao == 10">
                <img src="./assets/images/presente.png" alt="ícone presente" class="icon-process-info">
                <p class="process-info">
                  Participe gratuitamente no Portal. Basta estar com seu cadastro homologado para subir a proposta!
                </p>
              </div>
              <div class="process-mobile-button" *ngIf="isMobileScreen">
                <div class="process-status-wrapper">
                  <img src="./assets/images/SVG.png" alt="ícone status" class="icon-process-status">
                  <p class="process-status"> {{ processo.statusDescricao }}</p>
                </div>
                <button class="acessar-processo" (click)="handleProcessClick(processo)"> ACESSAR PROCESSO </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- END HOME -->
  <div>
    <app-quasela></app-quasela>
    <app-cards-obrigado></app-cards-obrigado>
    <app-footer></app-footer>
    <app-switcher></app-switcher>
  </div>
</div>
